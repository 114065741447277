import React, { useEffect } from 'react';

const projects = [
  {
    title: 'Fortune 1000 VPs and Directors Mining',
    description: 'Leads Generation, Spreadsheet, Data Mining.',
    tools: ['Leads Generation', 'Spreadsheet', 'Data Mining'],
  },
  {
    title: 'Finding Property Owner and Skip Tracing',
    description: 'Podio, Web Research, Data Entry, Excel.',
    tools: ['Podio', 'Web Research', 'Data Entry', 'Excel'],
  },
  {
    title: 'Researching B2B/B2C contacts',
    description: 'Leads Generation, Salesforce Management.',
    tools: ['Leads Generation', 'Salesforce Management'],
  },
  {
    title: 'Help with Web Research, Lead Generation, Data Entry, and more!',
    description: 'LinkedIn Leads Generation, Internet Research, Salesforce Data Entry.',
    tools: ['LinkedIn', 'Web Research', 'Salesforce'],
  },
  {
    title: 'Lawn Care Lead Scraping',
    description: 'Yellowpages, Yelp, B2B, Web Research.',
    tools: ['Yellowpages', 'Yelp', 'B2B', 'Web Research'],
  },
  {
    title: 'Lead Generation List Building',
    description: 'LinkedIn, Data.com, Excel.',
    tools: ['LinkedIn', 'Data.com', 'Excel'],
  },
  {
    title: 'Finding contact info for cannabis businesses and investors in the USA',
    description: 'LinkedIn, Web Research, Spreadsheet Management.',
    tools: ['LinkedIn', 'Web Research', 'Spreadsheet'],
  },
  {
    title: 'Search companies from Zoominfo and put contact information in spreadsheet',
    description: 'Zoominfo, Data Entry, Web Research.',
    tools: ['Zoominfo', 'Data Entry', 'Web Research'],
  },
];

const iconMapping = {
  'Leads Generation': '📊',
  Spreadsheet: '📋',
  'Data Mining': '🔍',
  Podio: '📝',
  'Web Research': '🌐',
  'Data Entry': '⌨️',
  Excel: '📈',
  'Salesforce Management': '⚙️',
  LinkedIn: '🔗',
  Yelp: '🌟',
  B2B: '💼',
  'Data.com': '💻',
  Zoominfo: '🔎',
  'Spreadsheet Management': '📊',
};

const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="bg-[#f8f9fa] py-12 mt-12">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-[#17315c] text-center mb-8">Our Projects</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
          {projects.map((project, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 hover:shadow-xl transition-all duration-300 ease-in-out relative"
            >
              {/* Project Title */}
              <h3 className="text-2xl font-semibold text-[#1a202c] mb-3">{project.title}</h3>

              {/* Project Description */}
              <p className="text-[#4a5568]  mb-6">{project.description}</p>

              {/* Tools List */}
              <ul className="text-sm text-[#77779e] space-y-1">
                {project.tools.map((tool, toolIndex) => (
                  <li
                    key={toolIndex}
                    className="flex items-center text-lg font-medium hover:text-[#1e40af] transition-colors duration-300"
                  >
                    <span className="mr-3">{iconMapping[tool]}</span>
                    <span className="inline-block">{tool}</span>
                  </li>
                ))}
              </ul>

              {/* Hover Effect Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-[#1e40af] to-transparent opacity-0 hover:opacity-20 transition-opacity duration-300"></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
