// import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faUserTie,
//   faMagnifyingGlass, // Icon for Web Research
//   faEnvelope, // Icon for Email List Building
//   faClipboardList, // Icon for Data Entry
//   faChartLine, // Icon for CRM Building
//   faHome, // Icon for Property Management
//   faUserClock, // Icon for Virtual Assistant
//   faDatabase, // Icon for Data Enrichment
//   faSearch, // Icon for Skip Tracing
// } from '@fortawesome/free-solid-svg-icons';
// import Popup from '../components/services/Popup';
// import leadgenimg from './../images/leadgeneration.jpg'
// import leadenrichment from './../images/leadenrichment.jpeg'
// import webrearch from './../images/webresearch.png'
// import emailpic from './../images/emailListBuilding.png'
// import virtualAssis from './../images/virtualAssistant.png'
// import dataentry from './../images/dataEntry.webp'
// import crmbuilding from './../images/crmbuilding.png'
// import propertyman from './../images/propertyManagemen.png'
// import skiptracing from './../images/skiptraceing.jpeg'

// const Services = () => {
//   const { hash } = useLocation();
//   const [isOpen, setIsOpen] = useState(false);
//   const [popupContent, setPopupContent] = useState({ title: '', description: '', image: '', process: [] });

//   const services = [
//     {
//       id: 'lead-generation',
//       title: 'Lead Generation',
//       description: 'We help businesses grow by finding high-quality leads tailored to your industry. Our team uses advanced techniques to ensure your sales pipeline is filled with potential clients.',
//       process: [
//         'Target Audience Identification – Define your ideal customer profile.',
//         'Prospecting – Use advanced tools to find relevant leads.',
//         'Qualification – Verify leads to ensure accuracy and fit.',
//       ],
//       suggestedImage: leadgenimg, // Update with actual image path
//       icon: faUserTie,
//     },
//     {
//       id: 'lead-enrichment',
//       title: 'Lead Enrichment',
//       description: 'Turn basic lead information into actionable insights that drive sales. We enrich your data to ensure that you have everything you need to reach out effectively.',
//       process: [
//         'Basic Info Collection – Gather names, emails, and phone numbers.',
//         'Data Enrichment – Add company insights and decision-making roles.',
//         'Validation – Ensure all data is accurate and up-to-date.',
//       ],
//       suggestedImage: leadenrichment, // Update with actual image path
//       icon: faDatabase,
//     },
//     {
//       id: 'web-research',
//       title: 'Web Research',
//       description: 'Save time by letting us do the heavy lifting in your market research. Our dedicated team will gather critical data that you can rely on.',
//       process: [
//         'Research Brief – Understand what data is needed.',
//         'Data Collection – Scrape or collect relevant info from trusted sources.',
//         'Report Generation – Deliver organized, easy-to-digest reports.',
//       ],
//       suggestedImage: webrearch, // Update with actual image path
//       icon: faMagnifyingGlass,
//     },
//     {
//       id: 'email-list-building',
//       title: 'Email List Building',
//       description: 'Increase your email outreach with verified, targeted email lists. We ensure you connect with the right people to maximize your marketing efforts.',
//       process: [
//         'Identify Target Audience – Define who you want to reach.',
//         'Email Finding – Collect verified emails using advanced tools.',
//         'List Delivery – Provide a clean, ready-to-use email list.',
//       ],
//       suggestedImage: emailpic, // Update with actual image path
//       icon: faEnvelope,
//     },
//     {
//       id: 'virtual-assistant',
//       title: 'Virtual Assistant',
//       description: 'Boost your productivity by outsourcing administrative tasks. Our VAs are trained to handle a wide range of tasks efficiently and professionally.',
//       process: [
//         'Task Assignment – Provide a list of tasks.',
//         'Task Execution – Our VAs handle day-to-day activities.',
//         'Reporting – Get updates on completed tasks.',
//       ],
//       suggestedImage: virtualAssis, // Update with actual image path
//       icon: faUserClock,
//     },
//     {
//       id: 'data-entry',
//       title: 'Data Entry',
//       description: 'Streamline your operations by letting us handle your data entry needs. We ensure data accuracy and timely delivery to keep your projects on track.',
//       process: [
//         'Data Collection – Gather all necessary data.',
//         'Entry & Cleanup – Enter data into your system and clean up inconsistencies.',
//         'Quality Assurance – Validate the accuracy of entered data.',
//       ],
//       suggestedImage: dataentry, // Update with actual image path
//       icon: faClipboardList,
//     },
//     {
//       id: 'crm-building',
//       title: 'CRM Building',
//       description: 'Improve your customer relationships with a customized CRM. Our tailored solutions help you manage your customer interactions seamlessly.',
//       process: [
//         'Data Collection – Gather customer and prospect information.',
//         'CRM Setup – Build your CRM, including fields for notes and follow-ups.',
//         'Integration – Sync with existing platforms.',
//       ],
//       suggestedImage: crmbuilding, // Update with actual image path
//       icon: faChartLine,
//     },
//     {
//       id: 'property-management',
//       title: 'Property Management Services',
//       description: 'We provide comprehensive property management services to ensure your investments are well-maintained and profitable.',
//       process: [
//         'Tenant Screening – Thoroughly evaluate potential tenants.',
//         'Rent Collection – Manage monthly rental collections.',
//         'Maintenance – Handle repairs and property upkeep efficiently.',
//       ],
//       suggestedImage: propertyman, // Update with actual image path
//       icon: faHome,
//     },
//     {
//       id: 'skip-tracing',
//       title: 'Skip Tracing',
//       description: 'Find missing or hard-to-locate property owners quickly. Our expertise ensures you get accurate and timely information.',
//       process: [
//         'Data Collection – Gather the basic information about the person you need to trace.',
//         'Search & Verify – Use databases to locate updated contact details.',
//         'Report Delivery – Provide a detailed report with the person\'s current contact info.',
//       ],
//       suggestedImage: skiptracing, // Update with actual image path
//       icon: faSearch,
//     },
//   ];

//   const openPopup = (title, description, image, process) => {
//     setPopupContent({ title, description, image, process });
//     setIsOpen(true);
//   };

//   const closePopup = () => {
//     setIsOpen(false);
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     if (hash) {
//       const element = document.getElementById(hash.substring(1));
//       if (element) {
//         element.scrollIntoView({ behavior: 'smooth' });
//       }
//     }
//   }, [hash]);

//   return (
//     <div className="bg-[#f0f4f8] py-12">
//       <div className="container mx-auto px-4 pt-12">
//         <h2 className="text-4xl font-bold text-[#17315c] text-center mb-8">Our Amazing Services</h2>

//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12 ">
//           {services.map((service) => (
//             <div key={service.id} id={service.id} className="bg-white shadow-lg rounded-lg p-6 hover:bg-[#e0e7f1] transition duration-300 cursor-pointer transform hover:scale-105 transition-transform duration-300" onClick={() => openPopup(service.title, service.description, service.suggestedImage, service.process)}>
//               <FontAwesomeIcon icon={service.icon} size="3x" className="text-[#38a0d9] mb-4" />
//               <h3 className="text-2xl font-semibold text-[#333] mb-4">{service.title}</h3>
//               <p className="text-[#333]">{service.description}</p>
//             </div>
//           ))}
//         </div>

//         {/* Render Popup if isOpen is true */}
//         {isOpen && (
//           <Popup title={popupContent.title} description={popupContent.description} image={popupContent.image} process={popupContent.process} onClose={closePopup} />
//         )}
//       </div>
//     </div>
//   );
// };

// export default Services;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Popup from "../components/services/Popup";
import leadgenimg from "./../images/leadgeneration.jpg";
import leadenrichment from "./../images/leadenrichment.jpeg";
import webrearch from "./../images/webresearch.png";
import emailpic from "./../images/emailListBuilding.png";
import virtualAssis from "./../images/virtualAssistant.png";
import dataentry from "./../images/dataEntry.webp";
import crmbuilding from "./../images/crmbuilding.png";
import propertyman from "./../images/propertyManagemen.png";
import skiptracing from "./../images/skiptraceing.jpeg";

const Services = () => {
  const { hash } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [popupContent, setPopupContent] = useState({
    title: "",
    description: "",
    image: "",
    process: [],
  });

  const services = [
    {
      id: "lead-generation",
      title: "Lead Generation",
      description:
        "We help businesses grow by finding high-quality leads tailored to your industry. Our team uses advanced techniques to ensure your sales pipeline is filled with potential clients.",
      process: [
        "Target Audience Identification – Define your ideal customer profile.",
        "Prospecting – Use advanced tools to find relevant leads.",
        "Qualification – Verify leads to ensure accuracy and fit.",
      ],
      suggestedImage: leadgenimg, // Update with actual image path
    },
    {
      id: "lead-enrichment",
      title: "Lead Enrichment",
      description:
        "Turn basic lead information into actionable insights that drive sales. We enrich your data to ensure that you have everything you need to reach out effectively.",
      process: [
        "Basic Info Collection – Gather names, emails, and phone numbers.",
        "Data Enrichment – Add company insights and decision-making roles.",
        "Validation – Ensure all data is accurate and up-to-date.",
      ],
      suggestedImage: leadenrichment, // Update with actual image path
    },
    {
      id: "web-research",
      title: "Web Research",
      description:
        "Save time by letting us do the heavy lifting in your market research. Our dedicated team will gather critical data that you can rely on.",
      process: [
        "Research Brief – Understand what data is needed.",
        "Data Collection – Scrape or collect relevant info from trusted sources.",
        "Report Generation – Deliver organized, easy-to-digest reports.",
      ],
      suggestedImage: webrearch, // Update with actual image path
    },
    {
      id: "email-list-building",
      title: "Email List Building",
      description:
        "Increase your email outreach with verified, targeted email lists. We ensure you connect with the right people to maximize your marketing efforts.",
      process: [
        "Identify Target Audience – Define who you want to reach.",
        "Email Finding – Collect verified emails using advanced tools.",
        "List Delivery – Provide a clean, ready-to-use email list.",
      ],
      suggestedImage: emailpic, // Update with actual image path
    },
    {
      id: "virtual-assistant",
      title: "Virtual Assistant",
      description:
        "Boost your productivity by outsourcing administrative tasks. Our VAs are trained to handle a wide range of tasks efficiently and professionally.",
      process: [
        "Task Assignment – Provide a list of tasks.",
        "Task Execution – Our VAs handle day-to-day activities.",
        "Reporting – Get updates on completed tasks.",
      ],
      suggestedImage: virtualAssis, // Update with actual image path
    },
    {
      id: "data-entry",
      title: "Data Entry",
      description:
        "Streamline your operations by letting us handle your data entry needs. We ensure data accuracy and timely delivery to keep your projects on track.",
      process: [
        "Data Collection – Gather all necessary data.",
        "Entry & Cleanup – Enter data into your system and clean up inconsistencies.",
        "Quality Assurance – Validate the accuracy of entered data.",
      ],
      suggestedImage: dataentry, // Update with actual image path
    },
    {
      id: "crm-building",
      title: "CRM Building",
      description:
        "Improve your customer relationships with a customized CRM. Our tailored solutions help you manage your customer interactions seamlessly.",
      process: [
        "Data Collection – Gather customer and prospect information.",
        "CRM Setup – Build your CRM, including fields for notes and follow-ups.",
        "Integration – Sync with existing platforms.",
      ],
      suggestedImage: crmbuilding, // Update with actual image path
    },
    {
      id: "property-management",
      title: "Property Management Services",
      description:
        "We provide comprehensive property management services to ensure your investments are well-maintained and profitable.",
      process: [
        "Tenant Screening – Thoroughly evaluate potential tenants.",
        "Rent Collection – Manage monthly rental collections.",
        "Maintenance – Handle repairs and property upkeep efficiently.",
      ],
      suggestedImage: propertyman, // Update with actual image path
    },
    {
      id: "skip-tracing",
      title: "Skip Tracing",
      description:
        "Find missing or hard-to-locate property owners quickly. Our expertise ensures you get accurate and timely information.",
      process: [
        "Data Collection – Gather the basic information about the person you need to trace.",
        "Search & Verify – Use databases to locate updated contact details.",
        "Report Delivery – Provide a detailed report with the person's current contact info.",
      ],
      suggestedImage: skiptracing, // Update with actual image path
    },
  ];

  const openPopup = (title, description, image, process) => {
    setPopupContent({ title, description, image, process });
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <div className="py-12 bg-gray-100">
      <div className="container mx-auto px-4 pt-12">
        <h2 className="text-4xl font-bold text-[#17315c] text-center mb-6">
          Our Services
        </h2>

        <div className="space-y-12">
          {services.map((service, index) => (
            <div
              key={service.id}
              id={service.id}
              className={`flex flex-col md:flex-row items-center rounded-lg p-6 ${
                index % 2 === 0 ? "md:flex-row-reverse" : ""
              }`}
            >
              {/* Image Section */}
              <div className="w-full md:w-1/2 p-4 flex justify-center">
                <img
                  src={service.suggestedImage}
                  alt={service.title}
                  style={{ width: "504px", height: "338px" }} // Set fixed size
                  className="object-cover rounded-lg shadow-lg"
                />
              </div>

              {/* Text Section */}
              <div className="w-full md:w-1/2  text-center md:text-left p-4">
                <h3 className="text-3xl font-semibold text-center text-[#333] mb-4">
                  {service.title}
                </h3>
                <p className="text-[#555] mb-4 text-center leading-relaxed">
                  {service.description}
                </p>
                <div className="flex justify-center mt-4">
                  <button
                    className="text-white bg-[#38a0d9] hover:bg-[#3090c7] py-2 px-6 rounded-full"
                    onClick={() =>
                      openPopup(
                        service.title,
                        service.description,
                        service.suggestedImage,
                        service.process
                      )
                    }
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {isOpen && (
          <Popup
            title={popupContent.title}
            description={popupContent.description}
            image={popupContent.image}
            process={popupContent.process}
            onClose={closePopup}
          />
        )}
      </div>
    </div>
  );
};

export default Services;
